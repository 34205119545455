import React, { useState } from 'react';
import { AddToastFunction } from "../../../utils/types/toast";
import { Card, Icon, Accordion, AccordionPanel } from "@salesforce/design-system-react";
import StackSetDriftSummaryCard from "./StacksetDriftSummary";
import ComingSoonSummaryCard from "./ComingSoonSummary";
import StackSetDeploymentSummaryCard from "./StacksetDeploymentSummary";
import QuickLinkList, {QuickLinkItem} from "../../../utils/components/quick_links/QuickLinks";

// FAQ data
const FAQs = [
    {
        question: "How to force approve an account closure?",
        answers: [
            `Goto tab "Account Closure" -> "Requests"`,
            "Find the account by name,id...",
            "Click on the dropdown",
            `Click "Force Approve"`,
        ]
    },
    {
        question: "How does Access Control work?",
        answers: [
            "Access Control enables administrators to define roles and permissions for users, ensuring that each individual has the appropriate level of access to the system."
        ]
    },
    {
        question: "What is Operational Efficiency?",
        answers: [
            "Operational Efficiency focuses on automating and simplifying administrative tasks, which helps reduce the time and resources needed to perform regular operations."
        ]
    },
    {
        question: "How does Real-Time Monitoring work?",
        answers: [
            "Real-Time Monitoring provides administrators with up-to-the-minute data on the status of operations, making it easy to track progress and quickly address any issues."
        ]
    },
    {
        question: "What are the benefits of Reporting and Analytics?",
        answers: [
            "Reporting and Analytics help administrators gather insights into system usage and user activity, allowing for data-driven decision-making and proactive management.",
            "For more details, visit <a href='https://www.example.com' target='_blank'>our reporting page</a>."
        ]
    },
    {
        question: "What is Enhanced Security?",
        answers: [
            "Enhanced Security ensures that sensitive information is protected through a variety of security protocols, reducing the risk of breaches and maintaining the integrity of the system."
        ]
    }
];

const FAQCard: React.FC = () => {
    const [activePanels, setActivePanels] = useState<string[]>([]);

    const handleTogglePanel = (panelId: string) => {
        setActivePanels((prevPanels) => prevPanels.includes(panelId)
            ? prevPanels.filter(id => id !== panelId)
            : [...prevPanels, panelId]);
    };

    return (
        <Card
            className="slds-card_boundary"
            heading={<p className="slds-text-heading_medium">FAQs & Guidelines</p>}
            icon={<Icon category="standard" name="question_feed" size="small"/>}
        >
            <Accordion>
                {FAQs.map((faq, index) => (
                    <AccordionPanel
                        key={index}
                        expanded={activePanels.includes(`panel${index}`)}
                        onTogglePanel={() => handleTogglePanel(`panel${index}`)}
                        summary={index + 1 + ". " + faq.question}
                        className="slds-box slds-m-bottom_small"
                    >
                        <ul className="slds-list_dotted">
                            {faq.answers.map((answer, answerIndex) => (
                                <li key={answerIndex} dangerouslySetInnerHTML={{ __html: answer }} />
                            ))}
                        </ul>
                    </AccordionPanel>
                ))}
            </Accordion>
        </Card>
    );
};

// Useful links Data
const usefulPCSKLinks = [
    { text: "Non-falcon account onboarding", link: "https://confluence.internal.salesforce.com/pages/viewpage.action?spaceKey=FALCON&title=Onboard+a+Non-Falcon+AWS+Account+to+PCSK" },
    { text: "PCSK Admin Documentation", link: "https://salesforce.quip.com/31d4A7oh9N8L" },
    { text: "Support Slack Channel", link: "https://salesforce.enterprise.slack.com/archives/C03KCPYR5MM" },
    { text: "Update Non-falcon Service Team", link: "https://confluence.internal.salesforce.com/display/FALCON/PCSK+JIT+User+Guide#:~:text=sh)%3A%C2%A0404028775706-,Adding/Deleting%20Account%20Approvers%20in%20PCSK,-The%20approvers%20list" },
];

const quickLinks: QuickLinkItem[] = [
    { text: "PCSK Admin Design", link: "https://salesforce.quip.com/31d4A7oh9N8L" },
    { text: "AWS Master Repo", link: "https://git.soma.salesforce.com/identity-access/aws-master/tree/master" },
    { text: "StackSets Repo", link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets" },
    { text: "New Deployment Process Runbook", link: "https://salesforce.quip.com/3jXhALmbFKDA" },
    { text: "PCSK Troubleshooting Guide", link: "https://salesforce.quip.com/1c4HA0Gl48Vr" },
    { text: "PCSK Availability", link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1" },
];


const UsefulLinksCard: React.FC = () => (
    <Card
        className="slds-card_boundary"
        heading={<p className="slds-text-heading_medium">Common Links</p>}
        icon={<Icon category="standard" name="link" size="small" />}
        style={{ minHeight: "200px" }}
    >
        <ul className="slds-list_vertical slds-has-dividers_around-space slds-p-around_x-small">
            {usefulPCSKLinks.map((item, index) => (
                <li key={index} className="slds-item">
                    <a
                        href={item.link}
                        className="slds-text-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.text}
                    </a>
                </li>
            ))}
        </ul>
    </Card>
);

type PCSKAdminHomePageProps = {
    addToast: AddToastFunction;
};

const PCSKAdminHomePage: React.FC<PCSKAdminHomePageProps> = ({ addToast }) => {

    return (
        <>
            <div className={"slds-m-left_small"}>
                <QuickLinkList usefulPCSKLinks={quickLinks} />
            </div>
        <div
            className="slds-grid slds-wrap slds-m-left_medium slds-m-right_medium slds-m-bottom_medium">

            {/* StackSets Drifts Summary */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-5 slds-p-around--x-small">
                <StackSetDriftSummaryCard/>
            </div>

            {/* StackSets Deployment Summary */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-5 slds-p-around--x-small">
                <StackSetDeploymentSummaryCard/>
            </div>

            {/* SCP Deployments Summary */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-5 slds-p-around--x-small">
                <ComingSoonSummaryCard topic={"SCP Deployments"}/>
            </div>

            {/* separator */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-1"></div>

            {/* FAQs */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_2-of-3 slds-p-around--x-small">
                <FAQCard/>
            </div>

            {/* Useful PCSK Links */}
            <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-3 slds-p-around_x-small">
                <UsefulLinksCard/>
            </div>
        </div>
        </>
    );
};

export default PCSKAdminHomePage;
