import {
    ErroredAccount,
    InsertAccountClosureResponse,
    PCSKAccountClosureApprover,
    PCSKAccountClosureItem,
    PCSKAdminClosureSummaryItem, ServicesComplianceExaminationResponse
} from "../../utils/types/accountClosure";
import {AccountClosureApiService} from "./service";
import {GenerateUUID} from "../../utils/uuid/uuid";
export class MockClosureApiService implements AccountClosureApiService {

    async getClosureSummary(): Promise<PCSKAdminClosureSummaryItem[]> {
        return new Promise<PCSKAdminClosureSummaryItem[]>((resolve) => {
            setTimeout(() => {
                const data: PCSKAdminClosureSummaryItem[] = [
                    new PCSKAdminClosureSummaryItem(502, "REQUESTED"),
                    new PCSKAdminClosureSummaryItem(200, "CLEANED"),
                    new PCSKAdminClosureSummaryItem(800, "CLOSED"),
                    new PCSKAdminClosureSummaryItem(2, "FAILED"),
                    new PCSKAdminClosureSummaryItem(1, "IN_PROGRESS"),
                    new PCSKAdminClosureSummaryItem(90, "CANCELLED"),
                ];
                resolve(data);
            }, 1200);
        });
    }

    async getClosureApprovers(aws_account_id: string) : Promise<PCSKAccountClosureApprover[]> {
        return new Promise<PCSKAccountClosureApprover[]>(resolve => {
            setTimeout(() => {
                const data = []
                data.push(new PCSKAccountClosureApprover("abc1@salesforce.com", "owner"))
                data.push(new PCSKAccountClosureApprover("abc2@salesforce.com", "team_member"))
                data.push(new PCSKAccountClosureApprover("abc8@salesforce.com", "team_member"))
                data.push(new PCSKAccountClosureApprover("abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8abc8@salesforce.com", "team_member"))
                data.push(new PCSKAccountClosureApprover("abc3@salesforce.com", "team_member"))
                resolve(data)
            }, 500);
        });
    }
    async approveAccountClosure(aws_account_id: string): Promise<PCSKAccountClosureItem> {
        return new Promise<PCSKAccountClosureItem>(resolve => {
            setTimeout(() => {
                resolve(generateRandomPCSKClosureItem(1));
            }, 500);
        });
    }

    async getAccountClosures(): Promise<PCSKAccountClosureItem[]> {
        return new Promise<PCSKAccountClosureItem[]>(resolve => {
            setTimeout(() => {
                const accounts: PCSKAccountClosureItem[] = [];
                for (let i = 1; i <= 1000; i++) {
                    accounts.push(generateRandomPCSKClosureItem(i));
                }
                resolve(accounts);
            }, 700);
        });
    }
}

const generateRandomPCSKClosureItem = (i : number) => {
    const randomGuid = `GUID_${Math.floor(Math.random() * 100)}`;
    const randomName = `Name_account_random_${Math.floor(Math.random() * 2000)}`;
    const randomAwsAccountId = `${Math.floor(Math.random() * 2000000000000)}`;
    const randomEmail = `email${Math.floor(Math.random() * 20)}@example.com`;
    const randomOrgAccountId = `ORG_${Math.floor(Math.random() * 1000)}`;
    const randomStatus = ['REQUESTED', 'CLEANED', 'CLOSED', 'EXPIRED', 'PROCESSING', 'LIMIT_EXCEEDED', 'FAILED'][Math.floor(Math.random() * 7)];
    const randomMinApprovers = Math.floor(Math.random() * 5).toString();
    const randomWaitingPeriod = Math.floor(Math.random() * 10).toString();
    const randomApprovedByList = Array.from({length: Math.floor(Math.random() * 5)}, () => `abc${Math.floor(Math.random() * 20)}@salesforce.com`);
    const randomForceApprovalReason = `urgent closure request`;

    const pcskAccountClosureItem = new PCSKAccountClosureItem({
        index: i,
        account_uuid: randomGuid,
        aws_account_id: randomAwsAccountId,
        request_timestamp: i & 1 ? Date.now() - (Math.floor(Math.random() * 100) * 1000) : Date.now() + (Math.floor(Math.random() * 100) * 1000),
        status: randomStatus,
        email: randomEmail,
        org_account_id: randomOrgAccountId,
        name: randomName,
        waiting_period: parseInt(randomWaitingPeriod),
        closure_work_item_id: `WorkItem_${Math.floor(Math.random() * 100)}`,
        approval_ids: Array.from({length: Math.floor(Math.random() * 5)}, () => `ApprovalID_${Math.floor(Math.random() * 100)}`),
        last_notification_timestamp: Date.now(),
        sent_notifications_count: Math.floor(Math.random() * 10),
        min_approvers: parseInt(randomMinApprovers),
        approval_expiry_timestamp: Date.now() + (Math.floor(Math.random() * 100) * 1000),
        requester_arn: `ARN_${Math.floor(Math.random() * 100)}`,
        force_approved_by_email: '',
        force_approval_reason: randomForceApprovalReason,
        services_examined: [`Service_${Math.floor(Math.random() * 10)}`],
        closed_at: Date.now(),
        approved_by_emails: randomApprovedByList,
        state: 'SomeState SomeState SomeState',
        is_approved: !!(i & 1),
        is_expired: false,
        has_crossed_waiting_period: true,
        is_closed: randomStatus === "CLOSED",
        scheduled_closure_message: 'SomeMessage',
    })
    return pcskAccountClosureItem
}
