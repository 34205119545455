import React, {useEffect, useState} from "react";
import {AddToastFunction} from "../../../../utils/types/toast";
import {ArtifactsApiServiceFactory} from "../../../../services/sam/factory";
import {Button, Card, Icon} from "@salesforce/design-system-react";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import QuickLinkList, {QuickLinkItem} from "../../../../utils/components/quick_links/QuickLinks";
import ArtifactSelector from "../../../../utils/components/input/artifactSelector";

type StackSetDeploymentsProps = {
    addToast: AddToastFunction;
    navigationBar: React.ReactNode;
};

const PCSKAdminSAMDeploymentsPage: React.FC<StackSetDeploymentsProps> = ({
                                                                                  navigationBar,
                                                                                  addToast,
                                                                              }) => {

    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);

    const [selectedArtifact, setSelectedArtifact] = useState<string>('');
    const [isDeployInProgress, setIsDeployInProgress] = useState<boolean>(false);
    const [disableDeployButton, setDisableDeployButton] = useState<boolean>(false);

    const artifactApiService = ArtifactsApiServiceFactory.getService();


    useEffect(() => {
        setDisableDeployButton(isDeployInProgress || !isValidChangeCase || !selectedArtifact)
    }, [isValidChangeCase, selectedArtifact, isDeployInProgress]);


    const handleDeployClick = async () => {
        try {
            setIsDeployInProgress(true)
            const response = await artifactApiService.deployArtifact(changeCaseID, selectedArtifact);
            addToast("Deploy Request Submitted Successfully!", `Artifact: ${selectedArtifact} has been submitted for deployment.`, "success");

        } catch (error) {
            console.log("Error deploying artifact", error);
            addToast("Deploy Request Submitted Failed!", `${error}`, "error");
        } finally {
            setIsDeployInProgress(false)
        }
    }

    // Useful links Data
    const usefulPCSKLinks: QuickLinkItem[] = [
        { text: "Admin SAM Deployment Guide", link: "https://salesforce.quip.com/MhU2AztXE3xL" },
        { text: "PCSK Troubleshooting Guide", link: "https://salesforce.quip.com/1c4HA0Gl48Vr" },
        { text: "PCSK Availability", link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1" },
    ];

    return (
        <div>
            {/* Render navigation bar */}
            {navigationBar}

            <QuickLinkList usefulPCSKLinks={usefulPCSKLinks} />

            <Card
                heading="PCSK SAM Deployments"
                icon={<Icon category="standard" name="template" size="small"/>}
                className={"slds-m-horizontal--small slds-m-bottom_small slds-p-right_medium slds-p-left_medium slds-p-bottom_medium slds-size_3-of-4"}
                bodyClassName={"slds-p-around_small"}
            >
                <div className="slds-grid slds-gutters slds-m-around_small">
                    <div className="slds-col slds-size_2-of-12 slds-text-align_right">
                        <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                    </div>
                    <div className="slds-col">
                        <ChangeCaseInput
                            isValidChangeCase={isValidChangeCase}
                            disableInput={isDeployInProgress}
                            changeCaseID={changeCaseID}
                            setChangeCaseID={setChangeCaseID}
                            setIsValidChangeCase={setIsValidChangeCase}
                            searchKey={""}
                        ></ChangeCaseInput>
                    </div>
                </div>


                <div className="slds-grid slds-gutters slds-m-around_small">
                    <div className="slds-col slds-size_2-of-12 slds-text-align_right">
                        <label className="slds-form-element__label"><strong>Artifact:</strong></label>
                    </div>
                    <div className="slds-size_10-of-12">
                        <div className="slds-grid slds-grid_vertical-align-center">
                            <div className="slds-col">
                                <ArtifactSelector
                                    setArtifact={setSelectedArtifact}
                                    disable={isDeployInProgress}
                                    prefix={""}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slds-grid slds-grid_align-end slds-m-horizontal_large slds-m-top_xx-large">
                    <div className="slds-grid slds-grid_vertical-align-center">
                        {isDeployInProgress && <CustomSpinnerOverlay size="small"/>}
                        <Button
                            label={isDeployInProgress ? "Deploying..." : "Submit for Deployment"}
                            variant="brand"
                            disabled={disableDeployButton}
                            onClick={handleDeployClick}
                            className="slds-m-left_large"
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default PCSKAdminSAMDeploymentsPage;
