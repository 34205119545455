import { StackSetOperation } from "./operation";
import { StackSetStagger } from "./stagger";

export const timestampToDate = (timestamp: number): string => {
    if (timestamp < 1) {
        return "unknown";
    }
    const date = new Date(timestamp * 1000);
    return `${date.toDateString()} ${date.toTimeString()}`;
};

export class StackSetsDeployment {
    commit_id: string;
    commit_title: string;
    created_at: number;
    deployment_status: string;
    developer_id: string;
    updated_at: number;
    changes: StackSetOperation[];
    can_expand: boolean;
    can_revert: boolean;

    constructor(
        commit_id: string,
        commit_title: string,
        created_at: number,
        deployment_status: string,
        developer_id: string,
        updated_at: number,
        operations: StackSetOperation[],
        can_expand: boolean = false,
        can_revert: boolean = false,
    ) {
        this.commit_id = commit_id;
        this.commit_title = commit_title;
        this.created_at = created_at;
        this.deployment_status = deployment_status;
        this.developer_id = developer_id;
        this.updated_at = updated_at;
        this.changes = operations;
        this.can_expand = can_expand;
        this.can_revert = can_revert;
    }

    updateStatus(newStatus: string): void {
        if (!newStatus) {
            throw new Error("A new status must be provided.");
        }
        this.deployment_status = newStatus;
        this.updated_at = Date.now();
    }

    hasActions(): boolean {
        return this.can_expand || this.can_revert;
    }

    getCreatedAtDate(): string {
        return timestampToDate(this.created_at);
    }

    getUpdatedAtDate(): string {
        return timestampToDate(this.updated_at);
    }

    toJSON(): Record<string, unknown> {
        return {
            commit_id: this.commit_id,
            commit_title: this.commit_title,
            created_at: this.created_at,
            deployment_status: this.deployment_status,
            developer_id: this.developer_id,
            updated_at: this.updated_at,
        };
    }

    static fromJSON(json: any): StackSetsDeployment[] {
        // Check if the input json and json.deployments are valid
        if (!json || !json.deployments || !Array.isArray(json.deployments)) {
            return [];
        }

        const deployments: StackSetsDeployment[] = [];

        for (let i = 0; i < json.deployments.length; i++) {
            const deployment = json.deployments[i];

            // Destructure properties with type checks for safety
            const {
                commit_id,
                commit_title,
                created_at,
                deployment_status,
                developer_id,
                updated_at,
                changes = [],
                can_expand,
                can_revert,
            } = deployment;

            // Validate and convert timestamps
            const parsedCreatedAt = Number(created_at);
            const parsedUpdatedAt = Number(updated_at);

            // Convert each change into a StackSetOperation object
            const parsedChanges = [];

            if (changes) {
                for (let j = 0; j < changes.length; j++) {
                    const change = changes[j];
                    const {
                        id,
                        created_at,
                        operation_type,
                        stackset_id,
                        stackset_status,
                        updated_at,
                        staggers = []
                    } = change;

                    const parsedChangeCreatedAt = Number(created_at);
                    const parsedChangeUpdatedAt = Number(updated_at);

                    // Convert staggers
                    const parsedStaggers = [];
                    if (staggers) {
                        for (let k = 0; k < staggers.length; k++) {
                            const stagger = staggers[k];
                            const {
                                created_at,
                                operation_status,
                                operation_type,
                                stagger_group_name,
                                updated_at
                            } = stagger;

                            const parsedStaggerCreatedAt = Number(created_at);
                            const parsedStaggerUpdatedAt = Number(updated_at);

                            if (isNaN(parsedStaggerCreatedAt) || isNaN(parsedStaggerUpdatedAt)) {
                                throw new Error(`Invalid timestamps in staggers: created_at(${created_at}) or updated_at(${updated_at})`);
                            }

                            parsedStaggers.push(new StackSetStagger(
                                parsedStaggerCreatedAt,
                                operation_type,
                                stagger_group_name,
                                operation_status,
                                parsedStaggerUpdatedAt
                            ));
                        }
                    }

                    parsedChanges.push(new StackSetOperation(
                        id,
                        parsedChangeCreatedAt,
                        stackset_status,
                        operation_type,
                        stackset_id,
                        parsedChangeUpdatedAt,
                        parsedStaggers
                    ));
                }
            }

            deployments.push(new StackSetsDeployment(
                commit_id,
                commit_title,
                parsedCreatedAt,
                deployment_status,
                developer_id,
                parsedUpdatedAt,
                parsedChanges,
                can_expand,
                can_revert
            ));
        }

        return deployments;
    }

}
